
  import { Component, Vue } from 'vue-property-decorator';
  import { CREATE_BOOKMAKER, RESET_STATE } from '@/store/bookmakers';
  import BookmakerEdit from '@/components/BookmakerEdit.vue';

  @Component({
    components: { BookmakerEdit }
  })
  export default class BookmakersCreate extends Vue {
    created() {
      this.$store.commit(RESET_STATE);
    }

    public save() {
      (this.$refs.bookmakerEdit as any).$v.$touch();

      if ((this.$refs.bookmakerEdit as any).$v.$error) {
        return;
      }

      this.$store.dispatch(CREATE_BOOKMAKER)
        .then(() => this.$router.push({ name: 'app.bookmakers.list' }));
    }
  }
